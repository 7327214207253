body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
    padding-top: 5rem; /* Adjust this value based on your header height */
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  header {
    background-color: #333;
    color: white;
    padding: 10px 0;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  h1, h2, h3 {
    color: #2c3e50;
  }
  
  form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 2rem auto;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  input {
    margin: 0.5rem 0;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  form button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem;
    margin-top: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1rem;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
  }
  
  .event-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .event-list-container h1 {
    width: 65%;
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  .event-list-vertical {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 65%;
  }

  .event-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .event-card {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }

  .event-card:hover {
    transform: translateY(-5px);
  }

  .event-card h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #2c3e50;
  }

  .event-card p {
    margin: 0.25rem 0;
  }

  .event-card p strong {
    color: #34495e;
  }

  .event-details {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  .register-link {
    display: block;
    margin-top: 1rem;
    text-align: center;
    color: #3498db;
    text-decoration: none;
  }
  
  .register-link:hover {
    text-decoration: underline;
  }
  
  .login-link {
    display: block;
    margin-top: 1rem;
    text-align: center;
    color: #3498db;
    text-decoration: none;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }

.create-event-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.create-event-container h1 {
  width: 65%;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.create-event-form {
  display: flex;
  flex-direction: column;
}

.create-event-form label {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.create-event-form input[type="text"],
.create-event-form input[type="number"],
.create-event-form input[type="datetime-local"] {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-container label {
  margin: 0;
  font-weight: normal;
}

.create-event-form button[type="submit"] {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.create-event-form button[type="submit"]:hover {
  background-color: #2980b9;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.forgot-password-link {
  display: block;
  margin-top: 1rem;
  text-align: center;
  color: #3498db;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #a5d6a7;
}

.cancel-cross {
  color: red;
  cursor: pointer;
  margin-right: 10px;
  font-size: 0.8em;
  display: inline-block;
  width: 20px;
  text-align: center;
}

.cancel-cross:hover {
  opacity: 0.7;
}

.event-details ul {
  list-style-type: none;
  padding-left: 0;
}

.event-details li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.participant-index {
  min-width: 25px;
  margin-right: 5px;
  text-align: right;
}

.participant-name {
  flex-grow: 1;
}

/* Adjust this if you want to align participants without a cross */
.event-details li:not(:has(.cancel-cross)) .participant-name {
  margin-left: 30px; /* Adjust this value to match the width of the cross + its margin */
}

.registration-form {
  margin-top: 1rem;
}

.name-inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.name-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.name-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.event-info-textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
  resize: vertical;
  min-height: 100px;
  margin: 0.5rem 0;
}

.event-info-textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.event-info-container {
  margin: 1rem 0;
}

.event-info-block {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 0.5rem;
  white-space: pre-wrap;
  line-height: 1.5;
}

.event-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.event-header h2 {
  margin: 0;
  flex: 1;
}

.share-button-icon {
  display: none;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #3498db;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.share-button-icon:hover {
  background-color: rgba(52, 152, 219, 0.1);
}

.share-button-icon svg {
  display: block;
}

@media screen and (max-width: 768px) {
  .share-button-icon.mobile-only {
    display: block;
  }
}

.resend-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.resend-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.resend-button:hover:not(:disabled) {
  background-color: #45a049;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-form label {
  font-weight: 500;
  color: #333;
}

.profile-form input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.password-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

.password-section h3 {
  margin-bottom: 1rem;
  color: #333;
}

.submit-button {
  background-color: #3498db;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #2980b9;
}

.message {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.profile-section {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-section h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  font-size: 1.25rem;
}

.password-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1rem;
}

.toggle-icon {
  font-size: 1.5rem;
  color: #3498db;
  font-weight: bold;
}

.message {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 4px;
  font-weight: 500;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.submit-button {
  background-color: #3498db;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
}

.submit-button:active {
  transform: translateY(0);
}

.disabled-input {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0.5rem;
}

.secondary-button:hover {
  background-color: #5a6268;
}

.email-change-form {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}
