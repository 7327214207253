.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.modal-button {
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-button.confirm {
  background-color: #3498db;
  color: white;
}

.modal-button.cancel {
  background-color: #e74c3c;
  color: white;
}

@media screen and (max-width: 480px) {
  .modal-content {
    width: 90%;
    padding: 1rem;
  }

  .modal-button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
