.header {
    background-color: #2c3e50;
    padding: 1rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    height: 3rem;
    box-sizing: border-box;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3498db;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Arial', sans-serif;
    margin-right: 2rem;
}

.nav-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-link {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s;
}

.nav-link:hover {
    background-color: #34495e;
}

.auth-section {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
}

.nav-toggle {
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    font-size: 1.2rem;
    display: none;
}

.auth-button, .language-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border-radius: 4px;
    box-sizing: border-box;
}

.auth-button:hover, .language-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.logo-link {
    text-decoration: none;
    color: inherit;
}

.logo-link:hover {
    opacity: 0.8;
}

.username {
    margin-right: 1rem;
}

.user-role {
    margin-left: 0.5rem;
    font-size: 0.9em;
    color: #888;
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 768px) {
    .nav-toggle {
        display: block;
    }

    .nav-container {
        flex-wrap: wrap;
    }

    .nav-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #2c3e50;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }

    .nav-list.nav-open {
        max-height: 500px; /* Adjust this value as needed */
    }

    .nav-link {
        width: 100%;
        text-align: center;
        padding: 0.75rem 1rem;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .mobile-only .username,
    .mobile-only .user-role,
    .mobile-only .language-button,
    .mobile-only .auth-button {
        display: block;
        width: 100%;
        margin: 0.5rem 0;
        text-align: center;
    }

    .mobile-only .user-role {
        margin-left: 0;
    }

    .logo-link {
        order: 1;
        flex-grow: 1;
    }

    .nav-list {
        order: 3;
        width: 100%;
    }

    .auth-section {
        order: 4;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
    }
}

.language-selector {
    margin-right: 1rem;
}

.language-selector select {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
}

.language-selector select:hover {
    background-color: #2980b9;
}

@media screen and (max-width: 768px) {
    .language-selector {
        margin: 0.5rem 0;
    }
}
