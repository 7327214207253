.btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border-radius: 4px;
    box-sizing: border-box;
}

.btn:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.btn-danger {
    background-color: #e74c3c;
}

.btn-danger:hover {
    background-color: #c0392b;
}

.btn-success {
    background-color: #2ecc71;
}

.btn-success:hover {
    background-color: #27ae60;
}
